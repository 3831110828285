import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { createRef, useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { References } from '../components';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Hero from '../components/hero';
import { ImageUtils, ResourceUtils } from '../utils';
import awsModernApps from '../styles/img/hero/aws_hero.svg';
import LocalizationService from '../services/localization-service';
import { STATIC_IMAGES } from '../resources/references';
import HelmetEx from '../components/helmet-ex';

const PAGE_MICROCOPY_MAP = {
    heroTitle: 'hero.title',
    heroTitle1: 'hero.title_1',
    heroTitle2: 'hero.title_2',
    pageSlug: 'page.slug',
    pageTitle: 'page.title',
    noIndex: 'page.no_index',
    ctaBody: 'cta.body',
    ctaTitle: 'cta.title',
    infoSectionBody: 'info_section.body',
    infoSectionColor: 'info_section.color',
    infoSectionTitle: 'info_section.title',
};

const REFERENCE_MICROCOPY_MAP = {
    referenceDownloadContentDescription: 'description',
    referenceDownloadContentTitle: 'title',
};

const DEFAULT_VALUES = {
    infoSectionColor: ''
};

const LandingPage = ({ data }) => {

    console.log('LandingPage', data);

    const { downloadContentResource, landingPageResource } = data;
    const { id: landingPageContentId, name: landingPageResourceName, resources: landingPageResources } = landingPageResource;
    const { id: downloadContentContentId, name: downloadContentResourceName, resources: downloadContentResources } = downloadContentResource ?? {};
    const [landingPageKeyPrefix, landingPageKey] = landingPageResourceName.split('.');
    const fullKeyPrefix = `${landingPageKeyPrefix}.${landingPageKey}`;
    const [, , downloadContentKey] = downloadContentResourceName?.split('.') ?? [];
    const [microcopy] = useState({
        ...ResourceUtils.getMicrocopy(new LocalizationService(landingPageResources), fullKeyPrefix, PAGE_MICROCOPY_MAP, DEFAULT_VALUES),
        ...ResourceUtils.getMicrocopy(new LocalizationService(downloadContentResources), `downloads.content.${downloadContentKey}`, REFERENCE_MICROCOPY_MAP)
    });
    console.log('microcopy', microcopy);
    const calendlyRef = createRef();
    console.log('downloadContentResourceName', downloadContentResourceName);
    const references = ResourceUtils.getReferences(data);
    console.log('References.result', references);

    useEffect(() => {
        Calendly.initInlineWidget({
            url: 'https://calendly.com/visar_gashi/website?hide_event_type_details=1',
            parentElement: calendlyRef.current,
            prefill: {},
            utm: {}
        });
        function calendlyEventListener(event) {
            if (!event.data?.event?.startsWith?.('calendly.')) return;
            if (!window.dataLayer) {
                console.warn('GTM data layer not found');
                return;
            }
            switch (event.data.event) {
                case 'calendly.date_and_time_selected':
                    window.dataLayer.push({
                        event: 'scheduling-date-and-time-selected',
                        landingPageKey,
                        pageUrl: window.location?.toString()
                    });
                    break;
                case 'calendly.event_scheduled':
                    window.dataLayer.push({
                        event: 'scheduling-completed',
                        landingPageKey,
                        pageUrl: window.location?.toString()
                    });
                    break;
            }
        }
        window.addEventListener(
            'message',
            calendlyEventListener
        );
        return () => {
            if (calendlyRef.current) calendlyRef.current.innerHtml = '';
            window.removeEventListener('message', calendlyEventListener);
        };
    }, [data]);

    return <Layout>
        <HelmetEx noIndex={microcopy.noIndex === 'true'}>
            <div metadata="title">Polymaths | {microcopy.pageTitle}</div>
            <div metadata="path">{microcopy.pageSlug}</div>
            <div metadata="canonicalurl">{microcopy.pageSlug}</div>
            {/* <div metadata="description">
            </div> */}
        </HelmetEx>
        <div className={`${microcopy.pageSlug ? microcopy.pageSlug : ''} landing-page`}>
            <div className="container">
                <Hero
                    cta={<ScrollLink className="btn btn-lg btn-brand-cta-1" offset={-60} to="schedule_call">Schedule a Call</ScrollLink>}
                    heading={microcopy.heroTitle !== `${fullKeyPrefix}.${PAGE_MICROCOPY_MAP.heroTitle}` ? microcopy.heroTitle : ''}
                    heading1={microcopy.heroTitle1}
                    heading2={microcopy.heroTitle2}
                    image={microcopy.heroImage ? ImageUtils.staticToGatsbyImage(STATIC_IMAGES[microcopy.heroImage]) : ImageUtils.staticToGatsbyImage(awsModernApps)}
                    imageAltText="Modern Applications"
                    options={{ showImageShadow: false }}
                />
            </div>
            <section className="bg-brand-primary-2 py-5 text-white">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-12 ps-5">
                            <h2 className="mb-0 pb-0">
                                {microcopy.infoSectionTitle}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`bg-brand-${microcopy.infoSectionColor || 'primary-1'} py-5`}>
                <div className="container">
                    <div className="row g-5">
                        <div className="col-12 ps-5">
                            <div dangerouslySetInnerHTML={{ __html: microcopy.infoSectionBody }}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5" id="schedule_call">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2>{microcopy.ctaTitle}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p className="lead pb-4" dangerouslySetInnerHTML={{ __html: microcopy.ctaBody }} ></p>
                            <div className="col-12" ref={calendlyRef}></div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card-component card card-standard testimonial p-4 mt-4 fst-italic">
                                <div className="card-body">
                                    <h4 className="fw-bold">
                                        "Like members of our own team"
                                    </h4>
                                    <p className="pb-4">
                                        The other team I outsourced to
                                        required a lot of management to get
                                        a consistent nd quality output.
                                        Polymaths does not. The team
                                        shows maturity, passion, patience,
                                        and flexibility, and their guidance
                                        has made for a great working
                                        experience. The outcome has been a
                                        better path forward
                                    </p>
                                    <div className="profile-pic w-25 mb-2">
                                        <StaticImage className="img-fluid img-thumbnail" src="../styles/img/pages/home/gopal-swamy.jpg" alt="Gopal Swamy" />
                                    </div>
                                    <span className="fw-bold">
                                        <span className="text-primary">Gopal Swamy</span>, CEO of conductiv
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <References className="mt-4" pageData={data} references={references} />

        </div>
        <Footer />
    </Layout>;
};

export default LandingPage;

export const query = graphql`
  query LandingPageQuery($landingPageResourceName: String!, $downloadContentResourceName: String) {
    landingPageResource: contentfulResourceSet(name: {eq: $landingPageResourceName}) {
      id
      name
      resources {
        key
        value {
          value
        }
        isHtml
      }
    }
    downloadContentResource: contentfulResourceSet(name: {eq: $downloadContentResourceName}) {
        id
        name
        resources {
          key
          value {
            value
          }
          isHtml
        }
    }
    downloadContent: allContentfulResourceSet(filter: {name: {regex: "/^downloads\\.content.*/"}}) {
        edges {
            node {
                name
                resources {
                    key
                    value {
                        value
                    }
                    isHtml
                }
            }
        }
    }
  }
`;